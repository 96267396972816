/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSS, getCSSVariableValue } from '../../../assets/ts/_utils'
import { useThemeMode } from '../../layout/theme-mode/ThemeModeProvider'
import axios from 'axios'
import { API_SERVER_URL } from '../../../../app/constants/config.constant'
import { KTIcon } from '../../../helpers'
import { useEquipmentList } from '../../../../app/hooks/lists/useEquipmentList'
import { Button, Input, Select, Form, DatePicker, Spin } from 'antd';
import { Option } from 'antd/es/mentions'
import { Dropdown1 } from '../../content/dropdown/Dropdown1'
import { HttpService } from '../../../../app/services/http.services'
// import { DeviceApi } from '../../api';


type Props = {
  className: string
}
interface ParameterData {
  parameter_name: string;
  parameter_value: string;
  unit_short_name: string;
}
interface User {
  parameter_data: ParameterData[];
}

const ChartsWidget3: React.FC<Props> = ({ className }) => {

  const [users, setUsers] = useState<User[]>([]);
  const [tempValue, setTempValue] = useState<any>([]); //val
  const [dateTime, setDateTime] = useState<any>([]);
  const [hasRefreshed, setHasRefreshed] = useState<boolean>(false);
  const chartRef = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();
  const [equipmentName, setEquipmentName] = useState<string>('');
  const [parameterName, setParameterName] = useState<string>('');
  const [unitName, setUnitName] = useState<string>('');
  const [graphLoading, setGraphLoading] = useState<boolean>(false);
  // const [equipmentId, setEquipmentId] = useState<number>(0);
  // const [equipmentName , setEquipmentName] = useState<string>('');
  const [lowValue, setLowValue] = useState<string>('');
  const [highValue, setHighValue] = useState<string>('');
  const [averageValue, setAverageValue] = useState<string>('');
  const [date, setDate] = useState<string>('');
  const [lowThresholdValue, setLowThresholdValue] = useState<string>('');
  const [highThresholdValue, setHighThresholdValue] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState('');
  const [ defaultEquipment, setDefaultEquipment] = useState<any>();
  const { equipmentList, activeEquipmentList, loadingEquipmentList } = useEquipmentList();
  const [equipmentId, setEquipmentId] = useState<number | undefined>(undefined);

  const refreshMode = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    if (hasRefreshed && tempValue.length > 0) {
      const chart = refreshMode();

      return () => {
        if (chart) {
          chart.destroy()
        }
      }
    }
  }, [chartRef, mode, hasRefreshed, tempValue])

  useEffect(() => {
    fetchGraph();
  }, [defaultEquipment]);

  // useEffect(() => {
  //   if(activeEquipmentList.length > 0) {
  //     setEquipmentId(activeEquipmentList[0].id);
  //     setEquipmentName(activeEquipmentList[0].name);
  //     setDefaultEquipment(activeEquipmentList[0]);
  //   }
  // }, [activeEquipmentList]);

  const fetchGraph = () => {
    setGraphLoading(true);
    const params = {
      equipment_id: equipmentId,
      // parameter_id: parameterId
      // start_date: startDate,
      // end_date: endDate
    };

    HttpService.get(`stream-data-graph`, params)
      .then(response => {
        const result = response.data?.results;
        setEquipmentName(response.data.equipment);
        setParameterName(response.data.parameter);
        setUnitName(response.data.unit);
        setLowValue(response.data.min);
        setHighValue(response.data.max);
        setAverageValue(response.data.average);
        setLowThresholdValue(response.data.threshold_value.threshold_value_low);
        setHighThresholdValue(response.data.threshold_value.threshold_value_high);
        setDate(response.data.date);
        // console.log(response.data.equipment);
        // setUsers(result);
        if (result) {

          setTempValue(result.pv);
          setDateTime(result.ca);

          setHasRefreshed(true);
          setGraphLoading(false);
          setErrorMessage('');
        }
        
      })
      .catch(error => {
        setGraphLoading(false);
        console.error('Error fetching graph data:', error);
        setErrorMessage('Your device appear to be offline. Please select another device.');
      });
  };

  function getChartOptions(height: number,): ApexOptions {
    const labelColor = getCSSVariableValue('--bs-gray-500')
    const borderColor = getCSSVariableValue('--bs-gray-200')
    const baseColor = getCSSVariableValue('--bs-info')
    const lightColor = getCSSVariableValue('--bs-info-light')
    const primaryColor = getCSSVariableValue('--bs-primary')
    const secondaryColor = getCSSVariableValue('--bs-info')

    return {
      series: [
        {
          name: parameterName,
          data: tempValue,
        },
      ],
      chart: {
        fontFamily: 'inherit',
        type: 'area',
        stacked: true,
        height: 350,
        toolbar: {
          show: true,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          columnWidth: '30%',
          borderRadius: 5,
        },
      },
      legend: {
        show: true,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: 'solid',
        opacity: 1,
      },
      stroke: {
        curve: 'smooth',
        show: true,
        width: 2,
        colors: [primaryColor],
        // show: true,
        // width: 2,
        // colors: ['transparent'],
      },
      xaxis: {
        categories: dateTime,
        tickAmount: 10, 
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
        crosshairs: {
          position: 'front',
          stroke: {
            color: primaryColor,
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
          enabled: true,
          formatter: undefined,
          offsetY: 0,
          style: {
            fontSize: '12px',
          },
        },
      },
         yaxis: {
        // min: -10,
        // max: 30,
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
          formatter: function (val) {
            return val + unitName
          }
        },
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: true,
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: '12px',
        },
        y: {
          formatter: function (val: any) {
            return val + unitName
          },
        },
      },
      colors: [lightColor],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      markers: {
        strokeColors: primaryColor,
        strokeWidth: 3,
      },
      annotations: {
        yaxis: [
          {
            y: highThresholdValue,
            borderColor: '#F51111',
            label: {
              borderColor: '#F51111',
              style: {
                color: '#17202A',
                background: '#fff'
              },
              text: `Threshold High ${highThresholdValue}`
            }
          },
          {
            y: lowThresholdValue,
            borderColor: '#F3EC07',
            label: {
              borderColor: '#F3EC07',
              style: {
                color: '#17202A',
                background: '#fff'
              },
              text: `Threshold Low ${lowThresholdValue}`
            }
          }
        ]
      }
    }
    
  }

  // const [parameterId, setParameterId] = useState<string | undefined>(undefined);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    await fetchGraph();
  };
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{equipmentName}</span>
          <span className=' fw-semibold fs-7'>{parameterName}</span>

          <span className='fw-semibold fs-7'>Date: {date}</span>
        </h3>
        
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>
          {/* <Dropdown1 /> */}
          <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
            <div className='px-7 py-5'>
              <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
            </div>

            <div className='separator border-gray-200'></div>

            <div className='px-7 py-5'>
              <div className='mb-10'>
                <form >
                  <div className='mb-10'>
                    <label className='form-label fw-bold'>Equipments:</label>
                    <div>
                      <Form.Item>
                        <Select
                          placeholder={"-- Select --"}
                          showSearch
                          allowClear
                          popupMatchSelectWidth={200}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          onChange={(e: any, option: any) => {
                            setEquipmentId(e);
                          }}
                          filterOption={(input, option: any) => option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0}
                        >
                          {activeEquipmentList && activeEquipmentList.map((item: any, index: any) => (
                            <Option key={index} value={item.id}>{item.name}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  

                  <div className='d-flex justify-content-end'>
                    <button className='btn btn-sm btn-primary' data-kt-menu-dismiss='true' onClick={handleSubmit} type="button">
                      Apply
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* end::Menu */}
        </div>
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}
        {errorMessage && <div className="alert alert-primary">{errorMessage}</div>}
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{ height: '350px' }}>
          {graphLoading && <Spin size="large" />}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          Low:&nbsp; <span className='fw-bold text-primary  fs-7'>{lowValue}</span>, &nbsp;
          High:&nbsp; <span className='fw-bold text-danger fs-7'>{highValue}</span>, &nbsp;
          Average:&nbsp; <span className='fw-bold text-success fs-7'>{averageValue}</span>
        </div>

        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export { ChartsWidget3 }