import React, { FC } from 'react'
import { Row, Col } from 'antd';
import PaginationTotalItem from '../../../../../app/components/Pagination/PaginationTotalItem';
import Pagination from '../../../../../app/components/Pagination/Pagination';
import { ItemsPerPageDefaultOptions } from '../../../../../app/utils/enums';
// import PaginationTotalItem from '../../../../../components/Pagination/PaginationTotalItem';
// import Pagination from '../../../../../components/Pagination/Pagination';
// import { ItemsPerPageDefaultOptions } from '../../../../../utils/enums';

const DeviceListPagination: FC<any> = props => {
    const { pagination, totalCount, handleOnChanged } = props;
    return (
        <div className="d-flex flex-stack flex-wrap pt-5">
            <div className='fs-6 fw-semibold text-gray-700'><PaginationTotalItem currentPage={pagination.currentPage} pageSize={pagination.pageSize} total={totalCount} /></div>
            <div className="pagination">
                <Pagination pageSizeOptions={ItemsPerPageDefaultOptions} currentPage={pagination.currentPage} pageSize={pagination.pageSize} total={totalCount} onChangePage={(page, pageSize) => handleOnChanged('pagination_change_page_and_size', page, pageSize)} />
            </div>
        </div>
    );
}

export default React.memo(DeviceListPagination);