import React, { FC } from 'react'
import { Button } from 'react-bootstrap';
import { CommonUtils } from '../../../../../app/utils';
import AntTable from '../../../../../app/components/Table/AntTable';
import { Card2 } from './Card2';
import { Spin } from 'antd';

const DeviceListing: FC<any> = props => {
    const { loading, listData, selectedRowKeys, onChangeSwitchToggle, handleOnChanged, handleTableChange, handleCallbackFunc } = props;

    const columns = [
        {
            dataIndex: "sl",
            key: 'sl',
            title: "#",
            sorter: true,
            width: '5%',
            render: (text: string, record: any, index: number) => ++index,
        },
        {
            dataIndex: "name",
            key: 'name',
            title: "Device Name",
            sorter: true,
            width: '20%',
            // render: (text: string, record: any, index: number) => <ViewAction entityId={record.id} actionItem={DeviceAction.COMMON_ACTION.VIEW} defaultViewText={text} handleCallbackFunc={handleCallbackFunc}><span className="grid-row-view-action">{text}</span></ViewAction>,
        },
        {
            dataIndex: "time",
            key: "time",
            title: "Updated At",
            sorter: true,
            width: '20%',
            render: (text: number, record: any, index: number) => (
                record.latest_stream_data ? (
                    record.latest_stream_data.updated_at
                ) : ""
            )
        },
        {
            dataIndex: "status",
            key: 'status',
            title: "Status",
            sorter: true,
            width: '10%',
            render: (text: number, record: any, index: number) => (
                record.latest_stream_data_status
            )
        },
        {
            dataIndex: "lat",
            key: 'lat',
            title: "Data",
            width: '40%',
            render: (text: number, record: any, index: number) => (
                <ul>
                    {record.latest_stream_data?.details?.map((item: any, id: number) => (
                        <>
                        { 
                            (item.datastream == 'lat_lng') ?
                                <span className="badge badge-success text-bg-light" style={{ marginRight: '10px' }}>
                                    <a href={`https://maps.google.com/?q=${item.datastream_value}`} target='_blank'  rel='noopener noreferrer'>
                                        Last Location
                                    </a>
                                </span>
                            :   <span className="badge badge-info text-bg-light" style={{ marginRight: '10px' }}>
                                    {item.parameter_name}: {item.datastream_value_masking} {item.unit_short_name}
                                </span>
                        }
                        </>
                        
                    ))}
                    {/* {record.latest_stream_data_carcopolo?.lat ? (
                        <span className="badge badge-success text-bg-light" style={{ marginRight: '10px' }}>
                            <a href={`https://maps.google.com/?q=${record.latest_stream_data_carcopolo?.lat},${record.latest_stream_data_carcopolo?.lng}`} target='_blank' rel='noopener noreferrer'>
                                Last Location
                            </a>
                        </span>
                    ) : (
                        <span></span>
                    )}
                    {record.latest_stream_data_carcopolo?.sensors?.map((item: any, id: number) => (

                        <span className="badge badge-info text-bg-light" style={{ marginRight: '10px' }}>
                            {item.name}: {item.value}
                        </span>
                    ))} */}
                </ul>
            ),
        },

    ];

    return (
        // <div className="px-6">
        //     <AntTable
        //         className="table-layout"
        //         rowSelection={false}
        //         rowSelectionPermission="auth:example:multiSelect"
        //         selectedRowKeys={selectedRowKeys}
        //         dataSource={listData}
        //         columns={columns}
        //         loading={loading}
        //         handleOnChanged={handleOnChanged}
        //         onChange={handleTableChange}
        //     />
        // </div>    
        <div className='row g-6 g-xl-5'>
            {loading && <Spin size="large" />}
            { listData.length > 0 ? listData.map((v:any)=>(
                <div className='col-md-6 col-xl-4'>
                    <Card2 
                        data={v} 
                    />
                </div>
            )) : <div className='text-center'>No Data Found</div>}
        </div>
        
    );
}

export default React.memo(DeviceListing);