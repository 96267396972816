/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import { DashboardApi } from '../../../../app/api';
import { Message } from '../../../../app/utils';
import { HttpService } from '../../../../app/services/http.services';
import { Spin } from 'antd';

type Props = {
	className: string
}

const CustomTablesWidget5: React.FC<Props> = ({ className }) => {
	const [errors, setErrors] = useState<any>({});
	const [itemData, setItemData] = useState<any>([]);
	const [category, setCategory] = useState<any>([]);
	const [topThreeDevice, setTopThreeDevice] = useState<any>([]);
	const [count, setCount] = useState(0)
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		loadTopThreeDeviceData()
	}, [])

	const loadTopThreeDeviceData = () => {
		setLoading(true);

		HttpService.get(`auth/dashboard/devices/top/temperature`)
			.then(response => {
				setTopThreeDevice(response.data.results);
				setLoading(false);
			})
			.catch(error => {
				console.error('Error fetching user data:', error);
			});
	};

	console.log(topThreeDevice);
	const myStyle = {
		backgroundColor: '#2f8bf1cf',
	};

	return (
		<div className={`card ${className}`} style={{ overflowY: 'auto', backgroundColor: '#2f8bf1b5' }}  >

			<div className='card-header border-0 pt-5'>
				<h3 className='card-title align-items-start flex-column'>
					<span className='card-label fw-bold fs-2 mb-1 text-white'>Equipment with highest temperature (24 hour)</span>
				</h3>
			</div>

			<div className="card-body d-flex align-items-end pt-0">
				<div className="d-flex align-items-center flex-column mt-3 w-100">
					{loading && <Spin size="small" />}
					{
						topThreeDevice?.map((item: any) => (
							<div className="d-flex justify-content-between fw-bold fs-6 text-white opacity-100 w-100 mt-auto mb-2">
								<span>{item.device_name}</span>
								<span>{item.avg_t1_value}  °C</span>
							</div>
						))
					}
				</div>
			</div>
		</div>
	)
}

export { CustomTablesWidget5 }
