import React, { useState, useEffect } from 'react';
import { API_SERVER_URL } from '../../../../../app/constants/config.constant';
import { HttpService } from '../../../../../app/services/http.services';
import { Spin } from 'antd';

interface EquipmentData {
  totalDevices: number;
  activeDevices: number;
  inactiveDevices: number;
  onlineDevices: number;
  active_percentage: number;
}

type Props = {
  className: string;
  color: string;
  img: string;
};

const CardsWidget20 = ({ className, color, img }: Props) => {
  const [equipmentData, setEquipmentData] = useState<EquipmentData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true); // Track loading state

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await HttpService.get(`auth/dashboard/devices/status/count`);
        const data = response.data.results as EquipmentData;
        setEquipmentData(data);
      } catch (error) {
        console.error('Error fetching equipment data:', error);
        setError('Please refresh again');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 500000);
    return () => clearInterval(intervalId);
  }, []);

  const renderContent = () => {
    if (isLoading) {
      return <div className="card-body"><p className='text-light'><Spin size="small" style={{ 'color': '#ffffff' }}/></p></div>;
    }

    if (error) {
      return <div className="card-body text-center"><p className='text-light'>{error}</p></div>;
    }

    if (equipmentData) {
      return (
        <>
          <div className="card-header pt-5">
            <div className="card-title d-flex flex-column">
              <span className="fs-2hx fw-bold text-white me-2 lh-1 ls-n2">{equipmentData.totalDevices}</span>
              <span className="text-white opacity-75 pt-1 fw-semibold fs-6">Equipments</span>
            </div>
          </div>
          <div className="card-body d-flex align-items-end pt-0">
            <div className="d-flex align-items-center flex-column mt-3 w-100">
              <div className="d-flex justify-content-between fw-bold fs-5 text-white opacity-100 w-100 mt-auto mb-2">
                <span>Online</span>
                <span>{equipmentData?.onlineDevices?equipmentData.onlineDevices:'-'}</span>
              </div>
              <div className="d-flex justify-content-between fw-bold fs-5 text-white opacity-100 w-100 mt-auto mb-2">
                <span>Active</span>
                <span>{equipmentData.activeDevices}</span>
              </div>
              <div className="d-flex justify-content-between fw-bold fs-5 text-white opacity-100 w-100 mt-auto mb-2">
                <span>Inactive</span>
                <span>{equipmentData?.inactiveDevices?equipmentData.inactiveDevices:'-'}</span>
              </div>
              {/* <div className="h-8px mx-3 w-100 bg-white bg-opacity-50 rounded">
                <div
                  className="bg-white rounded h-8px"
                  role="progressbar"
                  style={{ width: `${equipmentData.active_percentage}%` }}
                  aria-valuenow={50}
                  aria-valuemin={0}
                  aria-valuemax={100}
                ></div>
              </div> */}
            </div>
          </div>
        </>
      );
    }

    return null; // Handle unexpected scenarios (should not occur)
  };

  return (
    <div
      className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end ${className}`}
      style={{
        backgroundColor: color,
        backgroundImage: `url('${img}')`,
      }}
    >
      {renderContent()}
    </div>
  );
};

export { CardsWidget20 };
