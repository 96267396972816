import React, { FC } from 'react'
import { Button, Input, Select, Form } from 'antd';
// import { DeviceAction } from "../Actions/Device.actions";
import { Col, Row } from 'react-bootstrap';
import { RefreshIcon, ResetIcon } from '../../../../assets/images/icon/svg';
// import CreateAction from '../../../../../components/Actions/CreateAction';
// import BulkAction from '../../../../../components/Actions/BulkAction';
// import { RefreshIcon, ResetIcon } from '../../../../../../_metronic/assets/images/icon/svg';

const DeviceListFilter: FC<any> = props => {
    const { Search } = Input;
    const { Option } = Select;
    const { filters, handleOnChanged, handleCallbackFunc } = props;

    return (
        <div className='d-flex flex-wrap flex-stack mb-6'>
            <h3 className='fw-bolder my-2'>
                Equipments Statistics
            </h3>
            <div className='d-flex flex-wrap my-2'>
                <Form.Item name="search" className='mb-0 me-4'>
                    <Search size="large" placeholder="Search" onSearch={(value) => handleOnChanged('search', value)} />
                </Form.Item>

                <button
                    // disabled={isLoading}
                    title='Reset'
                    type='button'
                    className='btn btn-sm btn-primary me-4'
                    onClick={(event) => handleCallbackFunc(null, 'resetListing')}
                >
                    <ResetIcon rev={''} />
                </button>
                <button
                    title='Refresh'
                    type='button'
                    className='btn btn-sm btn-primary'
                    onClick={(event) => handleCallbackFunc(null, 'reloadListing')}
                >
                    <RefreshIcon rev={''} />
                </button>

                {/* <div className='me-4'>
                    <select
                        name='status'
                        data-control='select2'
                        data-hide-search='true'
                        className='form-select form-select-sm form-select-white w-125px'
                        defaultValue='Active'
                    >
                        <option value='Active'>Active</option>
                        <option value='Approved'>In Progress</option>
                        <option value='Declined'>To Do</option>
                        <option value='In Progress'>Completed</option>
                    </select>
                </div>
                    */}
            </div>
        </div>
    );
}
export default React.memo(DeviceListFilter);